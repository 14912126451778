import Apartment1 from "../../asset/apartment/apart (1).webp";
import Apartment2 from "../../asset/apartment/apart (2).webp";
import Apartment3 from "../../asset/apartment/apart (3).webp";
import Apartment4 from "../../asset/apartment/apart (4).webp";
import Apartment5 from "../../asset/apartment/apart (5).webp";
import Apartment6 from "../../asset/apartment/apart (6).webp";
import Apartment7 from "../../asset/apartment/apart (7).webp";
import Apartment8 from "../../asset/apartment/apart (8).webp";
import Apartment9 from "../../asset/apartment/apart (9).webp";
import Apartment10 from "../../asset/apartment/apart (10).webp";
import Apartment11 from "../../asset/apartment/apart (11).webp";
import Apartment12 from "../../asset/apartment/apart (12).webp";

const apartimages = [
  Apartment1,
  Apartment2,
  Apartment3,
  Apartment4,
  Apartment5,
  Apartment6,
  Apartment7,
  Apartment8,
  Apartment9,
  Apartment10,
  Apartment11,
  Apartment12,
];

export default apartimages;
