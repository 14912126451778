import Layton12 from "./layton (2).webp";
import Layton15 from "./layton (1).webp";
import Laurel10 from "./laurel (1).webp";
import Laurel12 from "./laurel (3).webp";
import Lyndon12 from "./lyndon (1).webp";
import Lyndon15 from "./lyndon (2).webp";
import Lyndon19 from "./lyndon (3).webp";

const Rumahimages = {
  Layton12,
  Layton15,
  Laurel10,
  Laurel12,
  Lyndon12,
  Lyndon15,
  Lyndon19,
};

export default Rumahimages;
