import { faBed, faMaximize, faShower } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import apartimages from "../../asset/apartment/apart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./apartment.scss";

const apartment = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    lazyload: true,
    responsive: [
      {
        breakpoint: 768, // Adjust breakpoint for your mobile size
        settings: {
          slidesToShow: 1, // Show only 1 slide on mobile screens
        },
      },
    ],
  };
  const apartwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=62812806111&text=Halo%20Jodie,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Apartment%20https://www.bsdcity-navapark.com/%20(Marigold)&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="apartment">
      <div id="apart" className="container-apart">
        <div className="text-apart">
          <div className="judul-apart">Apartment at Navapark</div>
          <div className="subjudul-apart">Marigold Botanical Living</div>
          <div className="cicilan-apart">Cicilan Start 18 Juta/Bulan</div>
          <div className="spek-apart">
            <FontAwesomeIcon icon={faMaximize} />
            <span> &nbsp; : 77.38 m² &nbsp;</span>
            <FontAwesomeIcon icon={faBed} />
            <span> &nbsp; : 2+1 &nbsp;</span>
            <FontAwesomeIcon icon={faShower} />
            <span> &nbsp; : 2+1 &nbsp;</span>
          </div>
          <div className="button-apart">
            <button className="button" onClick={apartwa}>
              <FontAwesomeIcon icon={faWhatsapp} />
              &nbsp; Contact Us
            </button>
          </div>
        </div>
        <div className="carousel-apart">
          <Slider {...settings}>
            {apartimages.map((image, index) => (
              <img
                className="kartugambar"
                key={index}
                src={image}
                alt="apartment"
              />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default apartment;
