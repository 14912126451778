import React from "react";
import "./header.scss";

const header = () => {
  return (
    <div className="header">
      <div className="container-header">
        <div className="judul-web"> NAVAPARK BSD CITY</div>
      </div>
      <div className="divider" />
      <div className="banner-header"></div>
    </div>
  );
};

export default header;
