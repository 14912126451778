import React from "react";
import rumahcard from "./rumahcard.json";
import Rumahimages from "../../asset/rumah/rumah.js";
import Cardrumah from "../../component/cardrumah/cardrumah.jsx";
import "./rumah.scss";

const rumah = () => {
  return (
    <div id="rumah" className="rumah">
      <div id="layton" className="judul-cluster">
        Layton
      </div>
      <div className="a">
        <div className="cardhome12-container">
          {rumahcard.Cardlayton.map((layton, index) => {
            // Determine the logo dynamically
            const fotorumah = Rumahimages[layton.imagerumah];
            return (
              <Cardrumah
                key={index}
                imagerumah={fotorumah} // Dynamically import logo images
                titlerumah={layton.titlerumah}
                subtitlerumah={layton.subtitlerumah}
                price={layton.price}
                subprice={layton.subprice}
                details={layton.details}
                whatsappLink={layton.whatsappLink}
                pagelink={layton.pagelink} // Pass the WhatsApp link// Pass the WhatsApp link
              />
            );
          })}
        </div>
      </div>
      <div id="laurel" className="judul-cluster">
        Laurel
      </div>
      <div className="a">
        <div className="cardhome12-container">
          {rumahcard.Cardlaurel.map((cards, index) => {
            // Determine the logo dynamically
            const fotorumah = Rumahimages[cards.imagerumah];
            return (
              <Cardrumah
                key={index}
                judulcluster={cards.judulcluster}
                imagerumah={fotorumah} // Dynamically import logo images
                titlerumah={cards.titlerumah}
                subtitlerumah={cards.subtitlerumah}
                price={cards.price}
                subprice={cards.subprice}
                details={cards.details}
                whatsappLink={cards.whatsappLink}
                pagelink={cards.pagelink} // Pass the WhatsApp link// Pass the WhatsApp link
              />
            );
          })}
        </div>
      </div>
      <div id="lyndon" className="judul-cluster">
        Lyndon
      </div>
      <div className="a">
        <div className="cardhome12-container">
          {rumahcard.Cardlyndon.map((cards, index) => {
            // Determine the logo dynamically
            const fotorumah = Rumahimages[cards.imagerumah];
            return (
              <Cardrumah
                key={index}
                judulcluster={cards.judulcluster}
                imagerumah={fotorumah} // Dynamically import logo images
                titlerumah={cards.titlerumah}
                subtitlerumah={cards.subtitlerumah}
                price={cards.price}
                subprice={cards.subprice}
                details={cards.details}
                whatsappLink={cards.whatsappLink}
                pagelink={cards.pagelink} // Pass the WhatsApp link// Pass the WhatsApp link
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default rumah;
