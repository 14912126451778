import React from "react";
import logoventure1 from "../../asset/logo/hongkongland-logo.webp";
import logoventure2 from "../../asset/logo/sinarmas-logo.webp";
import "./promo.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import Promoimage from "../../asset/Hardsell.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Fasilitasimages from "../../asset/fasilitas/fasilitas.js";
import logoclusterimages from "../../asset/header/logo-cluster.js";

const promo = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyload: true,
    responsive: [
      {
        breakpoint: 768, // Adjust breakpoint for your mobile size
        settings: {
          slidesToShow: 1, // Show only 1 slide on mobile screens
        },
      },
    ],
  };
  const settings1 = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    lazyload: true,
  };
  const wapromo = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=62812806111&text=Halo%20Jodie,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20(Promo)%20terbaru%20Rumah%20https://www.bsdcity-navapark.com/%20(promo)&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="promo">
      <div className="container-promo">
        <div className="container-promohead">
          <div className="judul-promo1"> A Joint Venture By </div>
          <div className="logo-venture">
            <div className="logoventure1">
              <img
                className="hongkongland"
                src={logoventure1}
                alt="hongkongland"
              />
            </div>
            <div className="logoventure2">
              <img
                className="sinarmasland"
                src={logoventure2}
                alt="sinarmasland"
              />
            </div>
          </div>
        </div>
        <div className="container-promobody">
          <div id="promo" className=" container-listpromo">
            <div className="containercontent">
              <div id="promo" className="judul-promo">
                Promo yang Tersedia
              </div>
              <div className="pointpenawaran">
                <div className="penawaranpoin">
                  <FontAwesomeIcon icon={faCheck} />
                  <span style={{ color: "black" }}>&nbsp;&nbsp;Siap Huni</span>
                </div>
                <div className="penawaranpoin">
                  <FontAwesomeIcon icon={faCheck} />
                  <span style={{ color: "black" }}>&nbsp;&nbsp;Free Lift</span>
                </div>
                <div className="penawaranpoin">
                  <FontAwesomeIcon icon={faCheck} />
                  <span style={{ color: "black" }}>
                    &nbsp;&nbsp;Sanitary by Kohler
                  </span>
                </div>
                <div className="penawaranpoin">
                  <FontAwesomeIcon icon={faCheck} />
                  <span style={{ color: "black" }}>
                    &nbsp;&nbsp;Free Smartdoor Lock
                  </span>
                </div>
                <div className="penawaranpoin">
                  <FontAwesomeIcon icon={faCheck} />
                  <span style={{ color: "black" }}>
                    &nbsp;&nbsp;Free Smarthome System
                  </span>
                </div>
                <div className="penawaranpoin">
                  <FontAwesomeIcon icon={faCheck} />
                  <span style={{ color: "black" }}>
                    &nbsp;&nbsp;Free Canopy
                  </span>
                </div>
                <div className="penawaranpoin">
                  <FontAwesomeIcon icon={faCheck} />
                  <span style={{ color: "black" }}>
                    &nbsp;&nbsp;Free AC Setiap Kamar
                  </span>
                </div>
              </div>
              <div className="disclaimer">*Disclaimer</div>
              <div className="button-wa-promo">
                <button onClick={wapromo} className="whatsapp">
                  <FontAwesomeIcon icon={faWhatsapp} /> Get More Promo
                </button>
              </div>
            </div>
            <div className="containergambar">
              <img
                className="penawarangambar"
                src={Promoimage}
                alt="hardsell"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="slider-fasilitas">
        <Slider {...settings}>
          {Fasilitasimages.map((image, index) => (
            <img
              className="kartugambar"
              key={index}
              src={image}
              alt={`Courtyard ${index + 1}`}
            />
          ))}
        </Slider>
      </div>
      <div className="devider-cluster">
        <div className="judul-cluster">Cluster In Navapark</div>
        <div className="carousel-logocluster">
          <Slider {...settings1}>
            {logoclusterimages.map((image, index) => (
              <img
                className="kartugambar12"
                key={index}
                src={image}
                alt="logo"
              />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default promo;
