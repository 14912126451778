import Fasilitas1 from "../../asset/fasilitas/fasilitas (1).webp";
import Fasilitas2 from "../../asset/fasilitas/fasilitas (2).webp";
import Fasilitas3 from "../../asset/fasilitas/fasilitas (3).webp";
import Fasilitas4 from "../../asset/fasilitas/fasilitas (4).webp";
import Fasilitas5 from "../../asset/fasilitas/fasilitas (5).webp";
import Fasilitas6 from "../../asset/fasilitas/fasilitas (6).webp";

const fasilitasimages = [
  Fasilitas1,
  Fasilitas2,
  Fasilitas3,
  Fasilitas4,
  Fasilitas5,
  Fasilitas6,
];

export default fasilitasimages;
