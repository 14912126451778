import React from "react";
import "./rukokom.scss";
import rukoimages from "../../asset/ruko/ruko";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignJustify,
  faBuilding,
  faMaximize,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const rukokom = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    lazyload: true,
    responsive: [
      {
        breakpoint: 768, // Adjust breakpoint for your mobile size
        settings: {
          slidesToShow: 1, // Show only 1 slide on mobile screens
        },
      },
    ],
  };
  const rukowa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=62812806111&text=Halo%20Jodie,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Ruko%20https://www.bsdcity-navapark.com/%20(Northpoint)&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="containerruko">
      <div id="ruko" className="commercial">
        <div className="slider-com">
          <Slider {...settings}>
            {rukoimages.map((image, index) => (
              <img
                className="kartugambar"
                key={index}
                src={image}
                alt="apartment"
              />
            ))}
          </Slider>
        </div>
        <div className="ket-commercial">
          <div className="judul-commercial">Multi Business in One Building</div>
          <div className="subjudul-comm">Ruko Northpoint at Navapark</div>
          <div className="cicilan-comm">Cicilan Start 70 Juta/Bulan</div>
          <div className="spek-comm">
            <FontAwesomeIcon icon={faMaximize} />
            <span> &nbsp; : 152 m² &nbsp;</span>
            <FontAwesomeIcon icon={faBuilding} />
            <span> &nbsp; : 767 m² &nbsp;</span>
            <FontAwesomeIcon icon={faAlignJustify} />
            <span> &nbsp; : 5+1 &nbsp;</span>
          </div>
          <div className="button-comm">
            <button className="button" onClick={rukowa}>
              <FontAwesomeIcon icon={faWhatsapp} />
              &nbsp; Contact Us
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default rukokom;
