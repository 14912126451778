import React from "react";
import "./about.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import logonava from "../../asset/logo/navapark-logogold.webp";

const about = () => {
  const aboutwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=62812806111&text=Halo%20Jodie,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20https://www.bsdcity-navapark.com/%20()&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="about">
      <div id="about" className="container-about">
        <div className="heading-about">
          <div className="judul">
            Higher Living at
            <div className="br">
              Navapark BSD City
              <hr className="devider" />
            </div>
          </div>

          <div className="desk">
            NavaPark BSD City, yang terletak di kawasan pengembangan kota BSD,
            adalah kawasan hunian premium yang telah menerima sertifikasi
            Greenship Neighborhood Platinum dari Green Building Council
            Indonesia. Ini menjadikannya kawasan hunian pertama di Indonesia
            yang menerima sertifikasi ini.
          </div>
          <div className="button">
            <button className="wa" onClick={aboutwa}>
              <FontAwesomeIcon icon={faWhatsapp} />
              &nbsp; Info Marketing
            </button>
          </div>
        </div>
        <div className="gambarabout">
          <div className="fitur-about">
            <div className="logo-navapark">
              <img className="logo" src={logonava} alt="navapark-logo" />
            </div>
            <div className="cardjudul">
              <div className="judul1">Marketing </div>
              <div className="desk1">Navarpark - BSD City</div>
              <div className="desk2">Luxury House BSD</div>
            </div>
            <div className="buttoncta">
              <button className="cta" onClick={aboutwa}>
                Show Unit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default about;
